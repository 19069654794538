import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { always, compose, evolve, pathOr } from 'ramda'
import FolioWork from '../FolioWork'

import SwiperCore, { Mousewheel, Autoplay, EffectCoverflow, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css/navigation';
import './HomeFolio.css'

SwiperCore.use([Mousewheel, Autoplay, EffectCoverflow, Navigation])

const getImage = compose(always, pathOr(null, ['node', 'childImageSharp', 'fluid']))

const HomeFolio = () => {
    const navigationPrevRefHF = React.useRef(null)
    const navigationNextRefHF = React.useRef(null)


  const { projects, images_sm, images_md, images_lg } = useStaticQuery(graphql`
		query {
			projects: allHomeprojectJson {
				edges {
					node {
						id
						project_name
						title
						description
						about
						cardImage_sm
						cardImage_md
						cardImage_lg
						googleplayurl
						appstoreurl
						backgroundColor
						type
						isWhite
					}
				}
			}
			images_sm: allFile(filter: { relativePath: { regex: "/-homefolio-image-sm/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 290, maxHeight: 350, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			images_md: allFile(filter: { relativePath: { regex: "/-homefolio-image-md/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 520, maxHeight: 437 quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			images_lg: allFile(filter: { relativePath: { regex: "/-homefolio-image-lg/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 722, maxHeight: 535, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`)

  return (
	<section className="homefolio">
		<div className="container">
			<header className="homefolio__header">
				<h3 className="homefolio__title">Приложения, которые мы&nbsp;разрабатываем</h3>
				<div className='hf-nav-block'>
				<div ref={navigationPrevRefHF} className='hf-prev'/>
				<div ref={navigationNextRefHF} className='hf-next'/>
				</div>
			</header>
		</div>
		
			<Swiper 
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRefHF.current;
					swiper.params.navigation.nextEl = navigationNextRefHF.current;
				}}
				effect={'coverflow'} 
				coverflowEffect={{
					rotate: 0,
					stretch: 0,
					depth: 50,
					modifier: 3,
					slideShadows: false,
				}}
				centeredSlides={true} 
				slidesPerView={'auto'} 
				loop={true} 
				spaceBetween={0}
				breakpoints={{
                    992: {
						spaceBetween: 32,
						coverflowEffect: {
							depth: 0,
							modifier: 1,
						},
                    },
					768: {
						spaceBetween: 32,
						coverflowEffect: {
							depth: 0,
							modifier: 1,
						},
					},
					532: {
						spaceBetween: 16,
						coverflowEffect: {
							depth: 0,
							modifier: 1,
						},
					},
				}}
				navigation={{
					prevEl: ".hf-prev",
					nextEl: ".hf-next",
				}}
				className='homefolio-slider'
			>
				{projects.edges.map(({ node }, key) => {
					const image_sm = images_sm.edges.find((image) => image.node.relativePath === node.cardImage_sm)
					const image_md = images_md.edges.find((image) => image.node.relativePath === node.cardImage_md)
					const image_lg = images_lg.edges.find((image) => image.node.relativePath === node.cardImage_lg)
				const project = evolve(
				{
				cardImage_sm: getImage(image_sm),
				cardImage_md: getImage(image_md),
				cardImage_lg: getImage(image_lg),
				},
				node
				)


				return <SwiperSlide key={key} className='homefolio-slide'>
					<FolioWork key={project.id} {...project}/>
				</SwiperSlide>
				})}
			</Swiper>
	</section>

 
  )
}

export default HomeFolio

