import React, { useEffect } from 'react'
import lottie from 'lottie-web'

import {  Link } from 'gatsby'


import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css/navigation';
import './HomeSteps.css'

import developmentAnimation from '../../images/steps/step_5.json'

import step1 from '../../images/steps/step_1.svg'
import step2 from '../../images/steps/step_2.svg'
import step3 from '../../images/steps/step_3.svg'
import step4 from '../../images/steps/step_4.svg'
import step5 from '../../images/steps/step_5.svg'
import step6 from '../../images/steps/step_6.svg'
import step7 from '../../images/steps/step_7.svg'
import step8 from '../../images/steps/step_8.svg'
import step9 from '../../images/steps/step_9.svg'
import step10 from '../../images/steps/step_10.svg'

SwiperCore.use([Autoplay, EffectCoverflow, Navigation ])

const HomeSteps = () => {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
	const developmentRef = React.useRef(null)


    useEffect(() => {
		lottie.loadAnimation({
			container: developmentRef.current,
			animationData: developmentAnimation,
			renderer: 'svg',
			loop: true,
			autoplay: true,
		})

	}, [])

    return (
        <section className="homesteps">
            <div className="container">
                <h2 className="homesteps-title">
                    Этапы разработки приложений
                </h2>
                <div className="homesteps-container">
                    <div className="homesteps-block">
                        <div className="homesteps-leftside">
                            <div className="homesteps-item">
                                <div className="homesteps-item__title fz-28">
                                    Как мы создаем приложения для iOS
                                    и&nbsp;Android
                                </div>
                                <div className="homesteps-item__descr">
                                    Разработка приложения - это не только программирование, но и целый спектр периферийных
                                    задач, которые важно реализовать
                                    для создания успешного продукта.
                                </div>
                                <div className="homesteps-item__image">
                                    <img src={step1} alt="step"/>
                                </div>
                            </div>
                        </div>
                        <div className="homesteps-rightside hideMobile">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step2} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        01
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Сбор информации и аналитика
                                </div>
                            </div>
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step3} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        02
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Техническое задание
                                </div>
                            </div>
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step4} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        03
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Прототип приложения
                                </div>
                            </div>
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step5} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        04
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    UX/UI дизайн интерфейса
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="homesteps-block hideMobile">
                        <div className="homesteps-leftside">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__title">
                                        Разработка ПО
                                    </div>
                                    <div className="homesteps-item__num">
                                        05
                                    </div>
                                </div>
                                <div className="homesteps-item__image">
                                    <div className="devsteps-media" ref={developmentRef}></div>
                                </div>
                            </div>
                        </div>
                        <div className="homesteps-rightside">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step7} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        06
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Тестирование продукта
                                </div>
                            </div>
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step8} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        07
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Публикация в сторах
                                </div>
                            </div>
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step9} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        08
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Продвижение приложения
                                </div>
                            </div>
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step10} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        09
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Гарантия на работы
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="showMobile">
                <div className="homesteps-block slider-wrap">
                    <Swiper 
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                        }}
                        effect={'coverflow'}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        loop={true}
                        autoplay={{delay: 2000 }}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 50,
                            modifier: 3,
                            slideShadows: false,
                        }}
                        navigation={{
                            prevEl: ".hs-prev",
                            nextEl: ".hs-next",
                        }}
                        className="homesteps-slider"
                        >
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step2} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        01
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Сбор информации и аналитика
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step3} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        02
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Техническое задание
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step4} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        03
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Прототип приложения
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step5} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        04
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    UX/UI дизайн интерфейса
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step6} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        05
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Разработка ПО
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step7} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        06
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Тестирование продукта
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step8} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        07
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Публикация в сторах
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step9} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        08
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Продвижение приложения
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="homesteps-slide">
                            <div className="homesteps-item">
                                <div className="homesteps-item__header">
                                    <div className="homesteps-item__image">
                                        <img src={step10} alt="step"/>
                                    </div>
                                    <div className="homesteps-item__num">
                                        09
                                    </div>
                                </div>
                                <div className="homesteps-item__title">
                                    Гарантия на работы
                                </div>
                            </div>
                        </SwiperSlide>
                        <div ref={navigationPrevRef} className='hs-prev'/>
                        <div ref={navigationNextRef} className='hs-next'/>
                    </Swiper>

                </div>
            </div>
            <div className="container">
                <div className="homesteps-controlers">
                    <Link to="/services/" className="homearticles-button btn btn--primary btn--outline w-full sm:w-auto p-4">
                    Подробнее об услугах
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default HomeSteps
