import React from 'react'


import './HomeDiplomas.css'

import diplomaIcon from '../../images/diploma.svg'
import diplomaBusiness from '../../docs/Малый бизнес.pdf'
import diplomaHealth from '../../docs/Здоровье и фитнес.pdf'
import diplomaSocial from '../../docs/Социальные сети.pdf'
import diplomaEducation from '../../docs/Образование наука работа.pdf'


const HomeDiplomas = () => {
    

  return (
	<section className="homediplomas">
		<div className="homediplomas-decor"></div>
		<div className="container">
			<div className="homediplomas-header">
				<h1>
					Топ-10
				</h1>
				<span> 
					по версии популярных рейтингов
				</span>
			</div>
			<div className="homediplomas-body">
				<a  href={diplomaBusiness} target={'_blank'} className="homediploma-card">
					<img className="homediplomas-icon" src={diplomaIcon} alt=""/>
					Разработчиков мобильных приложений <span>для малого бизнеса</span>
				</a>
				<a  href={diplomaHealth} target={'_blank'} className="homediploma-card">
					<img className="homediplomas-icon" src={diplomaIcon} alt=""/>
					Разработчиков мобильных приложений <span>для здоровья и фитнеса</span>
				</a>
				<a  href={diplomaSocial} target={'_blank'} className="homediploma-card">
					<img className="homediplomas-icon" src={diplomaIcon} alt=""/>
					Разработчиков мобильных приложений <span>социальных сетей</span>
				</a>
				<a href={diplomaEducation} target={'_blank'} className="homediploma-card">
					<img className="homediplomas-icon" src={diplomaIcon} alt=""/>
					Разработчиков мобильных приложений <span>для образования, науки и работы</span>
				</a>
			</div>
		</div>
	</section>

 
  )
}

export default HomeDiplomas

