import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'

import { useLocation } from '@reach/router'

import Modal from '../Modal'
import ContactForm from '../ContactForm'
import Interests from '../Interests'

import Img from 'gatsby-image'
import cn from 'classnames'
import * as classes from './foliowork.module.css'
import appstoreWhite from '../../images/app-store-rus-white.svg'
import googleplayWhite from '../../images/google-play-rus-white.svg'
import appstoreMain from '../../images/app-store-rus-main.svg'
import googleplayMain from '../../images/google-play-rus-main.svg'
// import googleColorBtn from '../../images/google-play-color.svg'
// import appColorBtn from '../../images/app-store-color.svg'
import Boop from '../Boop'


const FolioWork = ({
	id,
	project_name,
	title,
	description,
	type,
	about,
	isWhite,
	cardImage_sm,
	cardImage_md,
	cardImage_lg,
	backgroundColor,
	googleplayurl,
	appstoreurl,
}) => {

    const ref = useRef()
	const notificationInside = useRef(null)
	//modal
	const [open, setOpen] = useState(false)
	const handleClickOpen = () => setOpen(true)
	const handleClickClose = () => setOpen(false)

	const typeCn = cn(classes.type, { [classes.type_white]: isWhite })
	const titleCn = cn(classes.title, { [classes.title_white]: isWhite })
	const descriptionCn = cn(classes.description, { [classes.description_white]: isWhite })

	const location = useLocation()
	const isMainPage = location.pathname === '/'

	return (
		<>
			{ isMainPage ? ( 
				<>
					<div className='homefolio-slide__header'>
						<div className='homefolio-slide__left'>
							<h2>
							{type}
							</h2>
							<p>
							{about}
							</p>
						</div>
						<div className='homefolio-slide__right'>
						<button className='btn btn--primary homefolio-slide__btn' onClick={handleClickOpen}>
							Запросить оценку
						</button>
						<Modal isOpen={open} onClose={handleClickClose} notificationInside={notificationInside}>
							<div className="px-2">
								<h2 className="mb-6 text-22 sm:text-28 font-bold leading-tight">
									Мы ответим в течение 1 рабочего дня
								</h2>
								<ContactForm Interests={Interests} notificationRef={ref} onClose={handleClickClose} />
							</div>
						</Modal>
						</div>
					</div>
					<div className={`${classes.foliowork__mobile} homefolio-foliowork homefolio-foliowork--mobile ${isWhite ? 'homefolio-foliowork--white' : 'homefolio-foliowork--black'}`} style={{ background: backgroundColor }}>
						<div className={`${classes.content} homefolio-content`}>
							<div className={`${classes.picture}  homefolio-picture`} style={{ background: backgroundColor }}>
								<div to={`/portfolio/${project_name}/`}>
									<Img className={`${classes.image} ${classes.image_sm}`} fluid={cardImage_sm} />
									<Img className={`${classes.image} ${classes.image_md}`} fluid={cardImage_md} />
								</div>
							</div>
							<div className="padding-wrap">
								<Link to={`/portfolio/${project_name}/`} className={classes.title}>
									<h5>{title}</h5>
								</Link>
								<p className={classes.description}>{description}</p>
								<Link to={`/portfolio/${project_name}/`} className={`btn homefolio-btn ${isWhite ? 'btn--white' : 'btn--black'}`}>
									Подробнее 
								</Link>
							</div>
							
						</div>
					</div>
					<div className={`${classes.foliowork} homefolio-foliowork ${isWhite ? 'homefolio-foliowork--white' : 'homefolio-foliowork--black'}` } style={{ background: backgroundColor }}>
						<div to={`/portfolio/${project_name}/`} className={`${classes.picture} homefolio-picture`}>
							<Img className={`${classes.image} ${classes.image_lg}`} fluid={cardImage_lg} />
						</div>
						<div className={`${classes.content} homefolio-content`}>
							<Link to={`/portfolio/${project_name}/`} className={titleCn}>
								<h5>{title}</h5>
							</Link>
							<p className={descriptionCn}>{description}</p>
							<Link to={`/portfolio/${project_name}/`} className={`btn homefolio-btn ${isWhite ? 'btn--white' : 'btn--black'}`}>
								Подробнее
							</Link>
						</div>
					</div>
				</>
			) : (
				<>
					<div className={`${classes.foliowork__mobile} pagefolio-foliowork pagefolio-foliowork--mobile ${isWhite ? 'pagefolio-foliowork--white' : 'pagefolio-foliowork--black'}`} style={{ background: backgroundColor }}>
						<div className={`${classes.picture}  pagefolio-picture`} style={{ background: backgroundColor }}>
							<Link to={`/portfolio/${project_name}/`}>
								<Img fluid={cardImage_sm} />
							</Link>
						</div>
						<div className={`${classes.content} pagefolio-content`}>
							<Link to={`/portfolio/${project_name}/`} className={classes.title}>
								<h5>{title}</h5>
							</Link>
							<p className={classes.description}>{description}</p>
							{type ?<span className={`${classes.type} pagefolio-span`}>{type}</span> : ''}
							<div className={`${classes.links} pagefolio-links`}>
								{!!appstoreurl && (
									<a className={classes.link} href={appstoreurl} target="_blank">
										<img src={appstoreMain} alt="" />
									</a>
								)}
								{!!googleplayurl && (
									<a className={classes.link} href={googleplayurl} target="_blank">
										<img src={googleplayMain} alt="" />
									</a>
								)}
							</div>
						</div>
					</div>
					<div className={`${classes.foliowork} pagefolio-foliowork ${isWhite ? 'pagefolio-foliowork--white' : 'pagefolio-foliowork--black'}` } style={{ background: backgroundColor }}>
						<div className='pagefolio-container'>
							<Link to={`/portfolio/${project_name}/`} className={`${classes.picture} pagefolio-picture`}>
								<Img className={`${classes.image} ${classes.image_md}`} fluid={cardImage_md} />
								<Img className={`${classes.image} ${classes.image_lg}`} fluid={cardImage_lg} />
							</Link>
							<div className={`${classes.content} pagefolio-content`}>
								<Link to={`/portfolio/${project_name}/`} className={titleCn}>
									<h5>{title}</h5>
								</Link>
								<p className={descriptionCn}>{description}</p>
								{type ? <span className={`${typeCn} pagefolio-span`}>{type}</span> : ''}
								<div className={`${classes.links} pagefolio-links`}>
									{!!appstoreurl && (
										<Boop path={appstoreurl} styles={classes.link} scale={1.03}>
											{isWhite ? <img src={appstoreWhite} alt="" /> : <img src={appstoreMain} alt="" />}
										</Boop>
									)}
									{!!googleplayurl && (
										<Boop path={googleplayurl} styles={classes.link} scale={1.03}>
											{isWhite ? <img src={googleplayWhite} alt="" /> : <img src={googleplayMain} alt="" />}
										</Boop>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default FolioWork
