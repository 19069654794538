import React, { useState } from 'react'
import Parser from 'html-react-parser';
import './HomeFaq.css'
import arrowBlueIcon from '../../images/arrow-ico--blue.svg'


const HomeFaq = () => {

    const [faqs, setfaqs] = useState([
        {
            question: 'Почему такой большой разброс в ценах на рынке?',
            answer: 'На оценку стоимости может оказывать влияние множество факторов. В разных компаниях отличаются рейты (ставки) специалистов, а также их профессиональный уровень и подход к решению задач. При запросе у вас может не быть подробного описания проекта или технического задания, и цена формируется из общего понимания задачи исполнителем. Влияет также опыт и накопленная экспертиза, которая будет использоваться при реализации проекта. Низкая стоимость нередко бывает демпингом и попыткой стимулировать положительное решение клиента ценой. Но это, как правило, оказывает негативное влияние на итоговые результаты проекта.',
            open: false
        },
        {
            question: 'Занимаетесь ли вы поддержкой проекта? Сколько это стоит?',
            answer: 'Мы предоставляем гарантию на свои работы, по истечении ее срока мы можем заключить с вами договор на техническую поддержку. Он будет включать в себя определенное количество часов в месяц по установленной абонентской плате (здесь мы с вами обсудим все индивидуально). В рамках этих часов можно поддерживать актуальность и стабильность работы приложения или проводить доработки по функционалу.',
            open: false
        },
        {
            question: 'Беретесь ли вы за доработку приложений?',
            answer: 'Мы берем проекты на доработку. Перед ознакомлением с исходным кодом проекта подписываем NDA. Далее осуществляем ревью приложений и даем рекомендации по их улучшению. Формат работы может быть как Time & Materials (оплата за фактически затраченное на задачи время), так и Retainer (стоимость работы команды или отдельных специалистов за месяц) или FixPrice (зафиксированная стоимость всего проекта).',
            open: false
        },
        {
            question: 'Нативное и кроссплатформенное приложение, в чем разница?',
            answer: 'Нативные приложения пишутся изначально под конкретную операционную систему: iOS или Android. В этом случае используются оригинальные языки и инструменты разработки мобильных ОС. Кроссплатформенные приложения пишут сразу и для Android, и для iOS. При такой разработке исходный код продукта переводится в нативный и становится понятным конкретному устройству, в результате чего программа сможет взаимодействовать с установленной на нем ОС. Для этого  существуют несколько различных фреймворков: React Native, Xamarin и другие или наиболее популярный сейчас Flutter. А более подробно можно почитать в нашей статье <a href="https://appcraft.pro/blog/nativnaja_razrabotka/" target="_blank">“Технологии создания мобильных приложений”.</a>',
            open: false
        },
        {
            question: 'У вас нет проектов, похожих на мой, а вы точно справитесь?',
            answer: 'Если вы не увидели в нашем портфолио проекта, аналогичного вашему, возможно, просто с подобной идеей к нам еще не обращались, либо мы не можем публиковать кейс на сайте ввиду ограничений по конфиденциальности. Однако уникальных приложений на рынке существует очень много, и они обычно обладают стандартным функционалом: авторизация, подписки, каталог, личный кабинет, навигация, платежные системы и др. Наверняка необходимые вам элементы уже реализованы нашей студией в других проектах. Мы подробно обсудим с вами детали вашей идеи и предложим варианты реализации, исходя из накопленной продуктовой экспертизы.',
            open: false
        },
        {
            question: 'Передаете ли вы права на приложение?',
            answer: 'Да, мы прописываем в договоре информацию о том, что заказчик получает все права свой продукт, а с нашей стороны они наоборот отчуждаются.',
            open: false
        },
        {
            question: 'Какая информация вам нужна для оценки стоимости и сроков? ',
            answer: 'Чтобы мы смогли дать вам наиболее точную оценку, идеальным вариантом будет техническое задание. Но оно редко бывает готово на старте, поэтому в запросе расскажите нам, пожалуйста, основную суть приложения, для каких платформ потребуется разработка, какие функции наиболее важны, а какие можно убрать, будет ли в приложении монетизация и какие аналоги вы знаете на рынке. Еще будет очень здорово, если, присылая нам ссылку на похожий продукт, вы опишете, какие его функции не потребуются и каких, в свою очередь, не хватает. Как правило, на этапе оценки возникают дополнительные вопросы, поэтому мы попросим вас об онлайн или оффлайн-встрече.',
            open: false
        },
        {
            question: 'Другие студии могут предлагают разработать приложение за 2 недели. Почему у вас дольше?',
            answer: 'Обратите внимание сразу, что речь при запуске за несколько недель идет только об MVP - минимально жизнеспособном продукте c минимальным необходимым функционалом, имеющим ценность для пользователя. Это хороший вариант для тестирования идеи и понимания, действительно ли вам нужен мобильный сервис. С точки зрения технической реализации это будет адаптация шаблонных решений или разработка прототипа. Для запуска работоспособного продукта с 0 намного более реалистичными выглядит период от 2-3 месяцев. В этой <a href="https://appcraft.pro/blog/razrabotat-prilozhenie-za-paru-nedel/" target="_blank">статье</a> мы подробно описали все условия максимальной оптимизации по срокам. ',
            open: false
        },
        
    ]);

    const toggleFAQ = (idx) => {
        setfaqs(faqs.map((faq, i) => {
            if (i === idx) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }

            return faq
        }))
    }

  return (
    <section className="homefaq">
    <div className="container">
        <h2 className="homefaq-title">
            Частые вопросы
        </h2>
        <div className="homefaq-content">
            {faqs.map((faq, idx) => (
                <div className={"homefaq-item " + (faq.open ? 'opened' : '')} key={idx}>
                   <div className="homefaq-header" onClick={() => toggleFAQ(idx)}>
                        <p>
                            {faq.question}
                        </p>
                        <img src={arrowBlueIcon} alt="" className="" />
                    </div>
                    <div className="homefaq-body">
                        <p>
                        {Parser(faq.answer)}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    </div>
</section>
  )
}

export default HomeFaq
