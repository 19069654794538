import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from './useWindowSize'

import './HomeReliability.css'

const HomeReliability = () => {
	const windowSize = useWindowSize()
	const { cards, icons } = useStaticQuery(graphql`
		query {
			cards:	allReliabilityJson {
				edges {
					node {
						title
						descr
						icon
					}
				}
			}
			icons: allFile(filter: { relativePath: { regex: "/reliability-/" } }) {
				edges {
				  node {
					relativePath
					childImageSharp {
					  fluid(maxWidth: 500, quality: 500) {
						...GatsbyImageSharpFluid_withWebp
					  }
					}
					publicURL
				  }
				}
			  }
		}
	`)

	return (
		<section className="homereliability">

			<div className="container">
				<header className="homereliability__header">
					<h3 className="homereliability__title">Почему разработка приложения в&nbsp;нашей студии — это надежно?</h3>
				</header>

			{windowSize.width > 767 ? (
				<div className='homereliability__block'>
					{/* <Swiper
						slidesPerView={'auto'}
						loop={false}
						spaceBetween={32}
					> */}
					{cards.edges.map(({ node: card }, index) => {
					const { node: icon } = icons.edges.find((icon) => icon.node.relativePath === card.icon)
						return (
							<div key={index} className="homereliability__card">
							{/* <SwiperSlide key={index} className="homereliability__card"> */}
								<div className='homereliability__card-title'>
									{card.title}
								</div>
								<div className='homereliability__card-descr'>
									{card.descr}
								</div>
								<div className='homereliability__card-image'>
									<img src={icon.publicURL} alt="homereliability-image" />
								</div>
							{/* </SwiperSlide> */}
							</div>
						)
					})}
					{/* </Swiper> */}
				</div>
				) : (
				<div className='homereliability__block'>
					{cards.edges.map(({ node: card }, index) => {
						const { node: icon } = icons.edges.find((icon) => icon.node.relativePath === card.icon)
						return (
						<div className='homereliability__card' key={index}>
							<div className='homereliability__card-title'>
								{card.title}
							</div>
							<div className='homereliability__card-descr'>
								{card.descr}
							</div>
							<div className='homereliability__card-image'>
								<img src={icon.publicURL} alt="homereliability-image" />
							</div>
						</div>
						)
					})}
				</div>
			)}
			</div>

		</section>
	)

}

export default HomeReliability
