import React, { useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from './../HomeReliability/useWindowSize'

import './HomeSeo.css'
import 'swiper/css/pagination';
import prevImg from '../../images/seo-prev.svg'

SwiperCore.use([Pagination, Navigation])

const HomeSeo = () => {
	const windowSize = useWindowSize()
  const navigationPrevRefHS = React.useRef(null)
  const navigationNextRefHS = React.useRef(null)

  const {
    allHomeseoJson: { edges: items },
  } = useStaticQuery(graphql`
      query MyQuery {
        allHomeseoJson {
          edges {
            node {
              title
              cards {
                text
                title
              }
            }
          }
        }
      }
  `)




  return (
    <section className="homeseo">
      <div className="container">
        <div className='homeseo__prev'>
        <div className='homeseo__prev-title'>
              Разработка мобильных <br /> приложений
            </div>
            <div className='homeseo__prev-wrap'>
            <div className='homeseo__prev-left'>
            <div className='homeseo__prev-descr'>
            <p>
              В&nbsp;2022 году больше половины пользователей интернета активно пользуются смартфонами&nbsp;&mdash; в&nbsp;них заказывают еду, планируют путешествия, покупают билеты в&nbsp;кино и&nbsp;театр и&nbsp;выполняют десятки других повседневных делах.
              </p>
              <p>
              Но&nbsp;это удобно не&nbsp;только для пользователей&nbsp;&mdash; от&nbsp;приложения не&nbsp;меньше выигрывает и&nbsp;сам бизнес. Давайте разберем, для чего и&nbsp;в&nbsp;каких случаях вам понадобятся услуги разработки мобильных приложений. 
              </p>
              <p>
              Проектирование и&nbsp;разработка мобильных приложений&nbsp;&mdash; сложный процесс, требующий участия большого количества специалистов: менеджеров, маркетологов, разработчиков, дизайнеров, тестировщиков. В&nbsp;студии разработки мобильных приложений AppCraft все процессы идеально отлажены&nbsp;&mdash; мы&nbsp;11&nbsp;лет работаем одной командой без привлечения фрилансеров и&nbsp;создали более 200 приложений для интернет-магазинов, банков, служб доставки, игровых студий и&nbsp;пр.
              </p>
            </div>
          </div>
          <div className='homeseo__prev-right'>
            <img src={prevImg} alt="prevImg"/>
          </div>
            </div>
        </div>

        {windowSize.width > 767 ? (
          <div className='homeseo-content'>
            <Swiper
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRefHS.current;
                swiper.params.navigation.nextEl = navigationNextRefHS.current;
              }}
              slidesPerView={'auto'} 
              loop={false} 
              spaceBetween={32}
            >
              <div className='swiper-custom-navigation'>
                <div ref={navigationPrevRefHS} className='swiper-custom-arrow swiper-custom-arrow--prev'/>
                <div ref={navigationNextRefHS} className='swiper-custom-arrow swiper-custom-arrow--next'/>
              </div>

              {items.map(({ node }, node_index) => (
                <SwiperSlide className="homeseo-block" key={node_index}>
                    <div className='homeseo-title'>
                      {node.title}
                    </div>          
                    <div className='homeseo-cardlist'>
                      {node.cards.map( ({text, title}, card_index) => (
                      <div className='homeseo-card' key={card_index}>
                        <div className='homeseo-card__title'>
                          {title}
                        </div>
                        <div className='homeseo-card__text'>
                          {text}
                        </div>
                      </div>
                      ))}
                    </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <div className='homeseo-content'>
              {items.map(({ node }, node_index) => (
                <div className="homeseo-block" key={node_index}>
                    <div className='homeseo-title'>
                      {node.title}
                    </div>          
                    <div className='homeseo-cardlist'>
                      <Swiper
                        modules={[Pagination]}
                        slidesPerView={'auto'} 
                        loop={false} 
                        spaceBetween={0}
                        pagination={{ clickable: true }}
                      >
                        {node.cards.map( ({text, title}, card_index) => (
                        <SwiperSlide className='homeseo-card' key={card_index}>
                          <div className='homeseo-card__title'>
                            {title}
                          </div>
                          <div className='homeseo-card__text'>
                            {text}
                          </div>
                        </SwiperSlide>
                        ))}
                      </Swiper>
                  </div>
                </div>
              ))}
          </div>
        )}
        </div>
    </section>
   
  )
}

export default HomeSeo
