import React from 'react'

import './HomeStatic.css'

const HomeStatic = () => {

  return (
    <section className="homestatic">

      <div className="homestatic-block">
        <div className="homestatic-wrap">
          <div className="homestatic-item">
            <p className="homestatic-num">
              11
            </p>
            <p className="homestatic-desc">
              лет опыта <br /> разработки
            </p>
          </div>
          <div className="homestatic-item">
            <p className="homestatic-num">
            > 20
            </p>
            <p className="homestatic-desc">
            разных категорий <br /> проектов
            </p>
          </div>
          <div className="homestatic-item">
            <p className="homestatic-num">
            50 +
            </p>
            <p className="homestatic-desc">
            специалистов <br /> в команде
            </p>
          </div>
          <div className="homestatic-item">
            <p className="homestatic-num">
              > 200
            </p>
            <p className="homestatic-desc">
            созданных  <br /> приложений
            </p>
          </div>
        </div>
      </div>
        
    </section>
  )
}

export default HomeStatic
