// extracted by mini-css-extract-plugin
export var content = "foliowork-module--content--7b4f0";
export var description = "foliowork-module--description--10afd";
export var description_white = "foliowork-module--description_white--39669";
export var foliowork = "foliowork-module--foliowork--e7c10";
export var foliowork__mobile = "foliowork-module--foliowork__mobile--f3e07";
export var image_lg = "foliowork-module--image_lg--ab6a4";
export var image_md = "foliowork-module--image_md--a2c10";
export var image_sm = "foliowork-module--image_sm--b1da7";
export var link = "foliowork-module--link--12405";
export var links = "foliowork-module--links--9f16e";
export var picture = "foliowork-module--picture--91040";
export var title = "foliowork-module--title--4d33b";
export var title_white = "foliowork-module--title_white--ba928";
export var type = "foliowork-module--type--ee73d";
export var type_white = "foliowork-module--type_white--8a510";