import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import ScrollContainer from 'react-indiana-drag-scroll'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useBooped } from '../../hooks/useBooped'
import { animated } from 'react-spring'

import PhoneAnim from '../PhoneAnim'
import Award from '../Award'
import HomeStatic from '../HomeStatic'
import HomeClients from '../HomeClients'
// import HomePresent from '../HomePresent'

import Modal from '../Modal'
import ContactForm from '../ContactForm'
import Interests from '../Interests'

import './HomeHero.css'

import beeoLogo from '../../images/beeo.svg'
import gymmyLogo from '../../images/gymmy.svg'
import treepLogo from '../../images/treep.svg'
import osagoLogo from '../../images/osago.svg'
import heroVideo from '../../video/hero-video.mp4'
import rocketIcon from '../../images/rocket-ico.svg'
import ticketIcon from '../../images/ticket-ico.svg'
import arrowIcon from '../../images/arrow-ico.svg'
import callbackIcon from '../../images/callback.svg'

//Notification
import Notification from '../Notification'
import * as styles from '../Notification/Notification.module.css'

const heroProjects = [
	{
		title: 'Treeps',
		link: 'https://treep.ai/',
		logo: treepLogo,
		logoWidth: 24,
		logoHeight: 24,
	},
	{
		title: 'Gymmy',
		link: 'https://gymmy.fit/',
		logo: gymmyLogo,
		logoWidth: 24,
		logoHeight: 24,
	},
	{
		title: 'BeeO',
		link: 'https://beeo.app/',
		logo: beeoLogo,
		logoWidth: 24,
		logoHeight: 24,
	},
	{
		title: 'Osago',
		link: 'https://osago.one/',
		logo: osagoLogo,
		logoWidth: 24,
		logoHeight: 24,
	},
]

const HomeHero = ({ anchor }) => {
	const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
		typeof window !== 'undefined' && navigator.userAgent
	)
	const ref = useRef()
	const notificationInside = useRef(null)
	const breakpoints = useBreakpoint()
	const data = useStaticQuery(graphql`
		query {
			videoPoster: file(relativePath: { eq: "hero-video-poster.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 298, quality: 100) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)

	//modal
	const [open, setOpen] = useState(false)
	const handleClickOpen = () => setOpen(true)
	const handleClickClose = () => setOpen(false)

	//animated button
	const [isBooped, setIsBooped] = useState(false)
	const style = useBooped({ isBooped, setIsBooped, scale: 1.02 })
	const trigger = () => setIsBooped(true)

	const [isBooped2, setIsBooped2] = useState(false)
	const style2 = useBooped({ isBooped: isBooped2, setIsBooped: setIsBooped2, scale: 1.02 })
	const trigger2 = () => setIsBooped2(true)

	const renderProjects = heroProjects.map((project) => (
		<li key={project.title}>
			<a className="homehero-project" href={project.link} target="_blank" rel="nofollow">
				<img
					className="flex-shrink-0 mr-4"
					src={project.logo}
					alt=""
					width={project.logoWidth}
					height={project.logoHeight}
				/>
				<p>{project.title}</p>
			</a>
		</li>
	))

	const handleClick = () => {
		!!anchor && anchor.current.scrollIntoView({ behavior: 'smooth' })
	}

	return (
		<div className="homehero">
			<div className="homehero-inner container">
				<h1 className="homehero-title">Разработка мобильных приложений и сервисов</h1>
				<div className="homehero-body">
					<div className="homehero-text-wrap">
						<div className="homehero-leftside">
							<div className="homehero-icon">
								<img src={rocketIcon} alt=""/>
							</div>
							<p className="homehero-subtitle">
							Знаем, как решить вашу задачу <strong>оптимальным способом</strong>
							</p>
						</div>

						<div className="homehero-rightside">
							<div className="homehero-icon">
								<img src={ticketIcon} alt=""/>
							</div>
							<p className="homehero-subtitle">
							Подберем подходящее решение с учетом <strong>целей и возможностей</strong>
							</p>
						</div>
					</div>
					
					{isMobile ? (
						<div className="homehero-request-wrap">
							<button onClick={handleClickOpen} className="homehero-request">
								Заказать приложение
							</button>
							<a 
								className="homehero-request homehero-request--dark" 
								href="https://2meetup.in/company/studiya-mobilnoj-razrabotki-appcraft"
								target="_blank"
								rel="nofollow"
								style={style}>
									<img className="arrow-ico" src={callbackIcon} alt="" />
									Записаться на&nbsp;онлайн-консультацию	
							</a>
						</div>
						
					) : (

						<div className="homehero-request-wrap">
							<animated.button
								className="homehero-request"
								onClick={handleClickOpen}
								style={style}
								onMouseEnter={trigger}
							>
								Заказать приложение
							</animated.button>
							<animated.a
								className="homehero-request homehero-request--dark"
								href="https://2meetup.in/company/studiya-mobilnoj-razrabotki-appcraft"
								target="_blank"
								rel="nofollow"
								style={style2}
								onMouseEnter={trigger2}
							>
								<img className="arrow-ico" src={callbackIcon} alt="" />
								Записаться на&nbsp;онлайн-консультацию
							</animated.a>
						</div>
						
					)}
					<Modal isOpen={open} onClose={handleClickClose} notificationInside={notificationInside}>
						<div className="px-2">
							<h2 className="mb-6 text-22 sm:text-28 font-bold leading-tight">
								Мы ответим в течение 1 рабочего дня
							</h2>
							<ContactForm Interests={Interests} notificationRef={ref} onClose={handleClickClose} />
						</div>
					</Modal>
					<div className={styles.notifications} ref={notificationInside}>
						<Notification
							children={(add) => {
								ref.current = add
							}}
						/>
					</div>
				</div>

				<div className="homehero-media">
					{breakpoints.sm ? null : (
						<PhoneAnim
							video={heroVideo}
							poster={data.videoPoster.childImageSharp.fluid}
							className="homehero-anim"
						/>
					)}
				</div>
{/* 
				<ScrollContainer className="homehero-awards">
					<Award title="1 место">
						<p className="award-title">
							1 место <br /> Музыкальные и видео приложения
						</p>
						<p className="award-note">Рейтинг Рунета 2017</p>
					</Award>

					<Award title="2 место">
						<p className="award-title">
							2 место <br /> Развлекательные приложения
						</p>
						<p className="award-note">Рейтинг Рунета 2017</p>
					</Award>

					<Award title="3 место">
						<p className="award-title">
							3 место <br /> Разработчики мобильных приложений
						</p>
						<p className="award-note">Рейтинг Рунета 2018</p>
					</Award>

					<Award title="1 место">
						<p className="award-title">
							1 место <br /> Развлекательные приложения
						</p>
						<p className="award-note">Рейтинг Рунета 2019</p>
					</Award>

					<Award title="2 место">
						<p className="award-title">
							2 место <br /> Разработчики приложений для Android
						</p>
						<p className="award-note">Рейтинг Рунета 2019</p>
					</Award>

					<Award title="3 место">
						<p className="award-title">
							3 место <br /> Музыкальные и видео приложения
						</p>
						<p className="award-note">Рейтинг Рунета 2019</p>
					</Award>
				</ScrollContainer> */}

			</div>

			{/* <HomePresent /> */}

			<HomeStatic />

			<HomeClients />
		</div>
	)
}

export default HomeHero
