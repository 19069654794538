import React, { useState, useRef } from 'react'
import { animated } from 'react-spring'
import { useBooped } from '../../hooks/useBooped'

import Modal from '../Modal'
import ContactForm from '../ContactForm'
import Interests from '../Interests'

import './HomeTiming.css'
import arrowBlueIcon from '../../images/arrow-ico--blue.svg'
import clockIcon from '../../images/ic-clock.svg'
import eyeIcon from '../../images/ic-eye.svg'



const HomeTiming = () => {

    const ref = useRef()
	const notificationInside = useRef(null)

    //modal
    const [open, setOpen] = useState(false)
    const handleClickOpen = () => setOpen(true)
    const handleClickClose = () => setOpen(false)

    //animated button
    const [isBooped, setIsBooped] = useState(false)
    const style = useBooped({ isBooped, setIsBooped, scale: 1.02 })
    const trigger = () => setIsBooped(true)

  return (
    <section className="hometiming">
    <div className="container">
        {/* <PromoStep /> */}

        <div className="hometiming-container">
            <div className="hometiming-leftside">

                <h2 className="hometiming-title">
                    Сроки и&nbsp;стоимость разработки приложений
                </h2>

                <animated.button
                    className="hometeming-btn btn btn--primary w-full "
                    onClick={handleClickOpen}
                    style={style}
                    onMouseEnter={trigger}
                    >
                    Заказать приложение
                </animated.button>
                <Modal isOpen={open} onClose={handleClickClose} notificationInside={notificationInside}>
                    <div className="px-2">
                        <h2 className="mb-6 text-22 sm:text-28 font-bold leading-tight">
                            Мы ответим в течение 1 рабочего дня
                        </h2>
                        <ContactForm Interests={Interests} notificationRef={ref} onClose={handleClickClose} />
                    </div>
                </Modal>

            </div>

            <div className="hometiming-rightside">
                <div className="hometiming-kind">
                    <div className="hometiming-kind__item hometiming-kind__item--black">
                        <p className='hometiming-kind__item-title'>
                            Минимальные
                        </p>
                        <div className="hometiming-kind__item-footer">
                            <p className='huge'>
                                от 300 тысяч
                            </p>
                            <p>
                                от 5 недель
                            </p>
                        </div>
                    </div>
                    <div className="hometiming-kind__item hometiming-kind__item--blue">
                        <p className='hometiming-kind__item-title'>
                            Средние
                        </p>
                        <div className="hometiming-kind__item-footer">
                            <p className='huge'>
                                3 миллиона
                            </p>
                            <p>
                                4 месяца
                            </p>
                        </div>
                    </div>
                </div>

                <h3 className="hometiming-subtitle">
                    Из чего складываются такие цифры?
                </h3>
                <div className="hometiming-numbers">
                    <a href="https://appcraft.pro/blog/stoimost-razrabotki-prilozheniya/" target="_blank" className="hometiming-numbers__item">
                        <p className='hometiming-numbers__item-title'>
                            Стоимость разработки мобильного приложения
                        </p>
                        <p className='hometiming-numbers__item-descr'>
                            Описание всех шагов, из которых складывается цена разработки мобильного приложения.
                        </p>
                        <div className="hometiming-numbers__item-footer">
                            <p>
                            <img className="hometiming-ico" src={clockIcon} alt="" />
                            10 мин.
                            </p>
                            <p>
                            <img className="hometiming-ico" src={eyeIcon} alt="" />
                            1447
                            </p>
                            <span className="font-bold">
                                Читать
                                <img className="hometiming-ico" src={arrowBlueIcon} alt="" />
                            </span>
                        </div>
                    </a>
                    <a href="https://appcraft.pro/blog/kak_dolgo_razrabatyvat_prilozhenie/" target="_blank" className="hometiming-numbers__item">
                        <p className='hometiming-numbers__item-title'>
                        Сроки разработки приложения
                        </p>
                        <p className='hometiming-numbers__item-descr'>
                        Рассказываем, сколько времени занимает создание мобильного приложения
                        </p>
                        <div className="hometiming-numbers__item-footer">
                            <p>
                            <img className="hometiming-ico" src={clockIcon} alt="" />
                                5 мин.
                            </p>
                            <p>
                            <img className="hometiming-ico" src={eyeIcon} alt="" />

                            9934
                            </p>
                            <span className="font-bold ml-5">
                                Читать
                                <img className="hometiming-ico" src={arrowBlueIcon} alt="" />
                            </span>
                        </div>
                    </a>
                </div>
                </div>
            </div>
    </div>
</section>
  )
}

export default HomeTiming
