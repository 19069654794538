import React, { useRef } from 'react'

import Layout from '../components/Layout'
import HomeHero from '../components/HomeHero'
import HomeFolio from '../components/HomeFolio'
import HomeDiplomas from '../components/HomeDiplomas'
import HomeRequest from '../components/HomeRequest'


import HomeSteps from '../components/HomeSteps/HomeSteps'
import HomeTiming from '../components/HomeTiming/HomeTiming'
import ReviewsSlider from '../components/ReviewsSlider/ReviewsSlider'
import HomeFaq from '../components/HomeFaq'
import HomeSeo from '../components/HomeSeo'
import HomeReliability from '../components/HomeReliability'


const Home = () => {
	const node = useRef(null)
	return (
		<Layout
			title="Разработка мобильных приложений iOS, Android на заказ"
			content="✅ Разработка мобильных приложений на заказ для iPhone, iPad, Android в Москве и по всей России. Полный цикл работ, серверные решения, гарантия кода."
			keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
			ogSiteName="AppCraft"
			ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
			ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
		>
			<HomeHero anchor={node} />
			<HomeFolio />
			<HomeSteps />
			<HomeTiming />
			<HomeReliability />
			<HomeDiplomas />
			<ReviewsSlider />
			<HomeRequest />
			<HomeFaq />
			<HomeSeo />
		</Layout>
	)
}

export default Home
