import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Ticker from 'react-ticker'

import './HomeClients.css'

const HomeClients = () => {
  const {
    allFile: { edges: logos },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/clients/" } }, sort: { order: ASC, fields: relativePath }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 170, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            publicURL
          }
        }
      }
    }
  `)

  const renderLogos = logos.map(({ node: logo }, key) => (
    <div className="homeclients-unit" key={key}>
      <img src={logo.publicURL} alt="" />
    </div>
  ))

  return (
    <section className="homeclients container">
    
      <Ticker speed={5} height={100}>
        {() => <div className="homeclients-list">{renderLogos}</div>}
      </Ticker>
    </section>
  )
}

export default HomeClients
